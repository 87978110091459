import { PermissionEnums } from "@/enums/PermissionEnums";

const sharedRoutes = [
	{
		name: "profile",
		path: "profile",
		component: () => import("@/views/pages/profile/Profile.vue"),
	},
	{
		name: "dashboard",
		path: "dashboard",
		component: () => import("@/views/pages/dashboard/Dashboard.vue"),
		meta: {
			layoutSettings: {
				pageTitleHidden: true,
			},
		},
	},
	{
		name: "users.index",
		path: "users",
		component: () => import("@/views/Blank.vue"),
		redirect: {
			name: "users.list",
		},
		children: [
			{
				name: "users.list",
				path: "list",
				component: () => import("@/views/pages/users/UsersListing.vue"),
				meta: {
					permissions: [PermissionEnums.USERS_LIST],
				},
			},
			{
				name: "users.add",
				path: "add",
				component: () => import("@/views/pages/users/UsersAdd.vue"),
				meta: {
					permissions: [PermissionEnums.USERS_MANAGE],
				},
			},
			{
				name: "users.edit",
				path: "edit/:id",
				component: () => import("@/views/pages/users/UsersEdit.vue"),
				meta: {
					permissions: [PermissionEnums.USERS_MANAGE],
				},
			},
		],
	},
	{
		name: "declarations.index",
		path: "declarations",
		component: () => import("@/views/Blank.vue"),
		redirect: {
			name: "declarations.list",
		},
		children: [
			{
				name: "declarations.list",
				path: "list",
				component: () => import("@/views/pages/declarations/DeclarationsListing.vue"),
				meta: {
					permissions: [PermissionEnums.DECLARATIONS_LIST],
				},
			},
			{
				name: "declarations.structures",
				path: ":id/structures",
				component: () => import("@/views/pages/declarations/structures/DeclarationsStructures.vue"),
				meta: {
					permissions: [PermissionEnums.DECLARATIONS_LIST],
					layoutSettings: {
						pageTitleHidden: true,
					},
				},
			},
			{
				name: "declarations.add",
				path: "add",
				component: () => import("@/views/pages/declarations/DeclarationsAdd.vue"),
				meta: {
					permissions: [PermissionEnums.DECLARATIONS_MANAGE],
				},
			},
			{
				name: "declarations.edit",
				path: "edit/:id",
				component: () => import("@/views/pages/declarations/DeclarationsEdit.vue"),
				meta: {
					permissions: [PermissionEnums.DECLARATIONS_MANAGE],
				},
			},
		],
	},
	{
		name: "companies.index",
		path: "companies",
		component: () => import("@/views/Blank.vue"),
		redirect: {
			name: "companies.list",
		},
		children: [
			{
				name: "companies.list",
				path: "list",
				component: () => import("@/views/pages/companies/CompaniesListing.vue"),
				meta: {
					permissions: [PermissionEnums.COMPANIES_LIST],
				},
			},
			{
				name: "companies.preview",
				path: ":id/preview",
				component: () => import("@/views/pages/companies/preview/CompaniesPreview.vue"),
				meta: {
					permissions: [PermissionEnums.COMPANIES_LIST],
					layoutSettings: {
						pageTitleHidden: true,
					},
				},
			},
		],
	},
	{
		name: "orders.index",
		path: "orders",
		component: () => import("@/views/Blank.vue"),
		redirect: {
			name: "orders.list",
		},
		children: [
			{
				name: "orders.list",
				path: "list",
				component: () => import("@/views/pages/orders/OrdersListing.vue"),
				meta: {
					permissions: [PermissionEnums.ORDERS_LIST],
				},
			},
			{
				name: "orders.preview",
				path: ":id/preview",
				component: () => import("@/views/pages/orders/preview/OrdersPreview.vue"),
				meta: {
					permissions: [PermissionEnums.ORDERS_LIST],
				},
			},
			{
				name: "orders.documents",
				path: ":id/documents",
				component: () => import("@/views/pages/orders/documents/OrdersDocuments.vue"),
			},
		],
	},
	{
		name: "invoices.index",
		path: "invoices",
		component: () => import("@/views/Blank.vue"),
		redirect: {
			name: "invoices.list",
		},
		children: [
			{
				name: "invoices.list",
				path: "list",
				component: () => import("@/views/pages/invoices/InvoicesListing.vue"),
				meta: {
					permissions: [PermissionEnums.INVOICES_LIST],
				},
			},
			{
				name: "invoices.preview",
				path: ":id/preview",
				component: () => import("@/views/pages/invoices/preview/InvoicesPreview.vue"),
				meta: {
					permissions: [PermissionEnums.INVOICES_LIST],
					layoutSettings: {
						pageTitleHidden: true,
					},
				},
			},
		],
	},
	{
		name: "shipping.index",
		path: "shipping",
		component: () => import("@/views/Blank.vue"),
		redirect: {
			name: "shipping.list",
		},
		children: [
			{
				name: "shipping.list",
				path: "list",
				component: () => import("@/views/pages/shipping/ShippingListing.vue"),
				meta: {
					permissions: [PermissionEnums.SHIPPING_LIST],
				},
			},
			{
				name: "shipping.protocol",
				path: ":id/protocol",
				component: () => import("@/views/pages/shipping/protocol/ShippingProtocol.vue"),
				meta: {
					permissions: [PermissionEnums.SHIPPING_LIST],
				},
			},
			{
				name: "shipping.racks",
				path: ":id/racks",
				component: () => import("@/views/pages/shipping/racks/ShippingRacks.vue"),
				meta: {
					permissions: PermissionEnums.SHIPPING_LIST,
				},
			},
			{
				name: "shipping.racks.specification",
				path: ":id/racks/specification",
				component: () => import("@/views/pages/shipping/racks/specification/ShippingRacksSpecification.vue"),
			},
		],
	},
	{
		name: "racks.index",
		path: "racks",
		component: () => import("@/views/Blank.vue"),
		redirect: {
			name: "racks.list",
		},
		children: [
			{
				name: "racks.list",
				path: "list",
				component: () => import("@/views/pages/racks/RacksListing.vue"),
				meta: {
					permissions: [PermissionEnums.RACKS_LIST],
				},
			},
		],
	},
	{
		name: "rackHistories.index",
		path: "rack-histories",
		component: () => import("@/views/Blank.vue"),
		redirect: {
			name: "rackHistories.list",
		},
		children: [
			{
				name: "rackHistories.list",
				path: "list",
				component: () => import("@/views/pages/rack_histories/list/RackHistoriesListing.vue"),
				meta: {
					permissions: [PermissionEnums.RACK_HISTORIES_LIST],
				},
				children: [
					{
						name: "rackHistories.preview",
						path: "preview/:id?",
						component: () => import("@/views/Blank.vue"),
						meta: {
							permissions: PermissionEnums.RACK_HISTORIES_LIST,
							aside: () => import("@/views/pages/rack_histories/RackHistoriesNotesPreview.vue"),
						},
					},
				],
			},
		],
	},
	{
		name: "importLogs.index",
		path: "import-logs",
		component: () => import("@/views/Blank.vue"),
		redirect: {
			name: "importLogs.list",
		},
		children: [
			{
				name: "importLogs.list",
				path: "list",
				component: () => import("@/views/pages/logs_import/LogsImportListing.vue"),
				meta: {
					permissions: [PermissionEnums.IMPORT_LOGS_LIST],
				},
			},
		],
	},
	{
		name: "settings.index",
		path: "settings",
		component: () => import("@/views/pages/settings/Settings.vue"),
		meta: {
			permissions: [PermissionEnums.SETTINGS_MANAGE],
		},
	},
	{
		name: "structures.index",
		path: "structures",
		component: () => import("@/views/Blank.vue"),
		redirect: {
			name: "structures.list",
		},
		children: [
			{
				name: "structures.list",
				path: "list",
				component: () => import("@/views/pages/structures/listing/StructuresListing.vue"),
				meta: {
					permissions: [PermissionEnums.DECLARATIONS_LIST],
				},
			},
			{
				name: "structures.add",
				path: "add",
				component: () => import("@/views/pages/structures/StructuresAdd.vue"),
				meta: {
					permissions: [PermissionEnums.DECLARATIONS_MANAGE],
				},
			},
			{
				name: "structures.edit",
				path: "edit/:settingId/:structureId",
				component: () => import("@/views/pages/structures/StructuresEdit.vue"),
				meta: {
					permissions: [PermissionEnums.DECLARATIONS_MANAGE],
				},
			},
			{
				name: "structures.preview",
				path: "preview",
				component: () => import("@/views/pages/structures/StructuresPreview.vue"),
				meta: {
					permissions: [PermissionEnums.DECLARATIONS_MANAGE],
				},
			},
		],
	},
	{
		name: "signatures.index",
		path: "signatures",
		component: () => import("@/views/Blank.vue"),
		redirect: {
			name: "signatures.list",
		},
		children: [
			{
				name: "signatures.list",
				path: "list",
				component: () => import("@/views/pages/signatures/listing/SignaturesListing.vue"),
				meta: {
					permissions: [PermissionEnums.DECLARATIONS_LIST],
				},
			},
			{
				name: "signatures.add",
				path: "add",
				component: () => import("@/views/pages/signatures/SignaturesAdd.vue"),
				meta: {
					permissions: [PermissionEnums.DECLARATIONS_MANAGE],
				},
			},
			{
				name: "signatures.edit",
				path: "edit/:id",
				component: () => import("@/views/pages/signatures/SignaturesEdit.vue"),
				meta: {
					permissions: [PermissionEnums.DECLARATIONS_MANAGE],
				},
			},
		],
	},
];

export default sharedRoutes;
